const maccabi_haifa_swimming = {
  features: {
    requireAccountingExportNumber: true,
  },
  payments: {
    enabled: true,
    store: true,
    terminalName: "machaifas",
    accountingDocumentsExport: true,
    accountingDocumentsExportOld: true,
  },
};

const maccabi_haifa_general = {
  features: {
    requireAccountingExportNumber: true,
  },
  payments: {
    enabled: true,
    store: true,
    terminalName: "machaifag",
    accountingDocumentsExport: true,
    accountingDocumentsExportOld: true,
  },
};

const maccabi_haifa_kfar_galim = {
  features: {
    requireAccountingExportNumber: true,
  },
  payments: {
    enabled: true,
    store: true,
    terminalName: "machaifakg",
    accountingDocumentsExport: true,
    accountingDocumentsExportOld: true,
  },
};

const maccabi_ta = {
  features: {
    requireAccountingExportNumber: true,
  },
  payments: {
    enabled: true,
    store: true,
    terminalName: "maccabitlv",
    accountingDocumentsExport: true,
  },
};

let config = {
  maccabi_ashdod: {
    category: "superclub",
    payments: {
      enabled: true,
    },
  },
  maccabi_ashdod_basketball: {
    category: "basketball",
    features: {
      requireAccountingExportNumber: true,
    },
    payments: {
      enabled: true,
      store: true,
      terminalName: "macashdod",
      accountingDocumentsExport: true,
    },
  },
  maccabi_ashdod_gymnastics: {
    features: {
      requireAccountingExportNumber: true,
    },
    category: "gymnastics",
    payments: {
      enabled: true,
      store: true,
      terminalName: "macashdod",
      accountingDocumentsExport: true,
    },
  },
  maccabi_ashdod_volleyball: {
    category: "volleyball",
    features: {
      requireAccountingExportNumber: true,
    },
    payments: {
      enabled: true,
      store: true,
      terminalName: "macashdod",
      accountingDocumentsExport: true,
    },
  },
  maccabi_ashdod_netanya: {
    category: "volleyball",
    payments: {
      enabled: true,
      store: false,
      terminalName: "macashdod",
    },
  },
  maccabi_ashdod_badminton: {
    category: "badminton",
    features: {
      requireAccountingExportNumber: true,
    },
    payments: {
      enabled: true,
      store: false,
      terminalName: "macashdod",
      accountingDocumentsExport: true,
    },
  },
  maccabi_ashdod_basketball_women: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "macashdod",
    },
  },
  maccabi_ashdod_fencing: {
    category: "fencing",
    features: {
      requireAccountingExportNumber: true,
    },
    payments: {
      enabled: true,
      store: true,
      terminalName: "macashdod",
      accountingDocumentsExport: true,
    },
  },
  maccabi_ashdod_swimming: {
    category: "swimming",
    features: {
      requireAccountingExportNumber: true,
    },
    payments: {
      enabled: true,
      store: true,
      terminalName: "macashdod",
      accountingDocumentsExport: true,
    },
  },
  maccabi_ashdod_acrobatics: {
    category: "acrobatics",
    features: {
      requireAccountingExportNumber: true,
    },
    payments: {
      enabled: true,
      store: true,
      terminalName: "macashdod",
      accountingDocumentsExport: true,
    },
  },
  maccabi_ashdod_football: {
    category: "football",
    features: {
      requireAccountingExportNumber: true,
    },
    payments: {
      enabled: true,
      store: true,
      terminalName: "macashdod",
      accountingDocumentsExport: true,
    },
  },
  presentation_club: {
    category: "basketball",
    features: {
      requireAccountingExportNumber: true,
    },
    payments: {
      enabled: true,
      store: true,
      terminalName: "tltest",
      accountingDocumentsExport: true,
    },
  },
  hapoel_pt_handball: {
    logo: "hapoel_pt_handball",
    category: "handball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "hapoelpthb",
    },
  },
  hapoel_ironi_carmiel: {
    logo: "hapoel_ironi_carmiel.png",
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "hapcarmiel",
    },
    hideTotals: true,
  },
  galil_golan_league: {
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "galilleag",
    },
  },
  galil_golan_school: {
    logo: "galil_golan_school_new",
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "galilschoo",
    },
  },
  maccabi_haifa: {
    category: "superclub",
    payments: {
      enabled: true,
      accountingDocumentsExport: true,
      accountingDocumentsExportOld: true,
    },
  },
  maccabi_haifa_basketball: {
    category: "basketball",
    features: {
      requireAccountingExportNumber: true,
    },
    payments: {
      enabled: true,
      store: true,
      terminalName: "machaifa",
      accountingDocumentsExport: true,
      accountingDocumentsExportOld: true,
    },
  },
  maccabi_haifa_swimming: {
    ...maccabi_haifa_swimming,
    category: "swimming",
  },
  maccabi_haifa_waterpolo: {
    ...maccabi_haifa_swimming,
    category: "waterpolo",
  },
  maccabi_haifa_swimming_zikhron: {
    ...maccabi_haifa_swimming,
    category: "swimming",
  },
  maccabi_haifa_swimming_galei_haifa: {
    ...maccabi_haifa_swimming,
    category: "swimming",
  },
  maccabi_haifa_swimming_beit_biram: {
    ...maccabi_haifa_swimming,
    category: "swimming",
  },
  maccabi_haifa_taekwondo: {
    ...maccabi_haifa_general,
    category: "taekwondo",
  },
  maccabi_haifa_fencing: {
    category: "fencing",
    ...maccabi_haifa_general,
  },
  maccabi_haifa_triathlon: {
    category: "triathlon",
    ...maccabi_haifa_general,
  },
  maccabi_haifa_rhythmic_gymnastics: {
    category: "rhythmic_gymnastics",
    ...maccabi_haifa_general,
  },
  maccabi_haifa_judo: {
    category: "judo",
    ...maccabi_haifa_general,
  },
  maccabi_haifa_chess: {
    category: "chess",
    ...maccabi_haifa_general,
  },
  maccabi_haifa_bouldering: {
    category: "bouldering",
    ...maccabi_haifa_general,
  },
  maccabi_haifa_artistic_gymnastics: {
    category: "artistic_gymnastics",
    ...maccabi_haifa_general,
  },
  maccabi_haifa_athletics: {
    category: "athletics",
    ...maccabi_haifa_general,
  },
  maccabi_haifa_rugby: {
    category: "rugby",
    ...maccabi_haifa_general,
  },
  maccabi_haifa_volleyball: {
    category: "volleyball",
    ...maccabi_haifa_general,
  },
  maccabi_haifa_catchball: {
    category: "catchball",
    ...maccabi_haifa_general,
  },
  maccabi_haifa_women_soccer: {
    category: "soccer",
    ...maccabi_haifa_general,
  },
  maccabi_haifa_camps: {
    category: "general",
    ...maccabi_haifa_general,
  },
  maccabi_haifa_combat_fitness: {
    category: "combat_fitness",
    ...maccabi_haifa_general,
  },
  maccabi_haifa_kfar_galim: {
    category: "athletics",
    ...maccabi_haifa_kfar_galim,
  },
  maccabi_haifa_kfar_galim_volleyball: {
    category: "volleyball",
    ...maccabi_haifa_kfar_galim,
  },
  maccabi_haifa_kfar_galim_wrestling: {
    category: "wrestling",
    ...maccabi_haifa_kfar_galim,
  },
  maccabi_haifa_kfar_galim_surfing: {
    category: "surfing",
    ...maccabi_haifa_kfar_galim,
  },
  maccabi_haifa_aerobic_gymnastics: {
    category: "aerobic_gymnastics",
    ...maccabi_haifa_general,
  },
  maccabi_ironi_carmiel: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "carmielbc",
    },
  },
  kiryat_ono_wolves: {
    category: "football",
    payments: {
      enabled: true,
      store: true,
      terminalName: "onowolves",
    },
  },
  hapoel_gan_yavne: {
    category: "basketball",
    payments: {
      enabled: true,
      store: false,
      terminalName: "ganyavnebc",
      accountingDocumentsExport: true,
      accountingDocumentsExportOld: true,
    },
  },
  hapoel_kiryat_tivon: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "kiryativon",
    },
  },
  maccabi_shaarayim: {
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "sharaiym",
    },
  },
  maccabi_shaaraim_school: {
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "shaschool",
    },
  },
  elitzur_kiryat_ata: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "kiryatabc",
    },
  },
  betar_jerusalem_basketball: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "beitarjer",
    },
  },
  otzma_jerusalem: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "otzmajeru",
    },
  },
  sva_volleyball: {
    category: "volleyball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "shfelasvg",
    },
  },
  hapoel_mazkeret_batya: {
    category: "basketball",
    payments: {
      enabled: false,
      store: false,
    },
  },
  kiryat_yam_sc: {
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "kyamschoo",
    },
  },
  kiryat_yam_sc_league: {
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "kyamleague",
    },
  },
  tzafririm_holon: {
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "zafrirhol",
    },
  },
  ironi_ness_ziona: {
    features: {
      requireAccountingExportNumber: true,
    },
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "nesszionab",
      accountingDocumentsExport: true,
    },
  },
  bnei_herzliya_basketball: {
    category: "basketball",
    payments: {
      enabled: false,
      store: false,
    },
  },
  beitar_ashdod_basketball: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "tltest",
    },
  },
  demo: {
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "tltest",
    },
  },
  maccabi_rishon_lezion: {
    features: {
      requireAccountingExportNumber: true,
    },
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "macrishon",
      accountingDocumentsExport: true,
    },
  },
  maccabi_ta: {
    category: "superclub",
    payments: {
      enabled: true,
      accountingDocumentsExport: true,
    },
  },
  maccabi_ta_handball: {
    category: "handball",
    ...maccabi_ta,
  },
  maccabi_ta_volleyball: {
    category: "volleyball",
    ...maccabi_ta,
  },
  maccabi_ta_acrobatics: {
    category: "acrobatics",
    ...maccabi_ta,
  },
  maccabi_ta_athletics: {
    category: "athletics",
    ...maccabi_ta,
  },
  maccabi_ta_judo: {
    category: "judo",
    ...maccabi_ta,
  },
  maccabi_ta_rhythmic_gymnastics: {
    category: "rhythmic_gymnastics",
    ...maccabi_ta,
  },
  maccabi_ta_artistic_gymnastics: {
    category: "artistic_gymnastics",
    ...maccabi_ta,
  },
  maccabi_ta_table_tennis: {
    category: "table_tennis",
    ...maccabi_ta,
  },
  maccabi_ta_weightlifting: {
    category: "weightlifting",
    ...maccabi_ta,
  },
  maccabi_ta_archery: {
    category: "archery",
    ...maccabi_ta,
  },
  maccabi_ta_swimming: {
    category: "swimming",
    ...maccabi_ta,
  },
  maccabi_ta_artistic_swimming: {
    category: "swimming",
    ...maccabi_ta,
  },
  maccabi_ta_general: {
    category: "general",
    ...maccabi_ta,
  },
  maccabi_ta_cycling: {
    category: "general",
    ...maccabi_ta,
  },
  maccabi_ta_rugby: {
    category: "general",
    ...maccabi_ta,
  },
  hapoel_haifa_fc: {
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "haphaifa",
    },
  },
  hapoel_ironi_bat_yam: {
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "batyamfc",
    },
  },
  hapoel_ashdod_handball: {
    category: "handball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "hapashdod",
    },
  },
  maccabi_rehovot: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "macrehovot",
    },
  },
  hapoel_nesher: {
    category: "basketball",
    features: {
      requireAccountingExportNumber: true,
    },
    payments: {
      enabled: true,
      store: true,
      terminalName: "hapnesher",
      accountingDocumentsExport: true,
    },
  },
  hapoel_nesher_soccer: {
    features: {
      requireAccountingExportNumber: true,
    },
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "hapnesher",
      accountingDocumentsExport: true,
    },
  },
  hapoel_nesher_climbing: {
    features: {
      requireAccountingExportNumber: true,
    },
    category: "climbing",
    payments: {
      enabled: true,
      store: true,
      terminalName: "hapnesher",
      accountingDocumentsExport: true,
    },
  },
  ale_yahud: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "aleyehud",
    },
  },
  hapoel_drom_hasharon: {
    category: "volleyball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "dromhash",
    },
  },
  zichron_yaakov_bc: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "maczichron",
    },
  },
  sc_ashkelon: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "scashkelon",
    },
  },
  yuval_shabtay: {
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "yuvalshabt",
    },
  },
  maccabi_matan: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "macmatan",
    },
  },
  hapoel_gezer_bc: {
    category: "basketball",
    features: {
      requireAccountingExportNumber: true,
    },
    payments: {
      enabled: true,
      store: true,
      terminalName: "hapgezer",
      accountingDocumentsExport: true,
    },
  },
  hapoel_tel_aviv_fc: {
    category: "soccer",
    features: {
      requireAccountingExportNumber: true,
    },
    payments: {
      enabled: true,
      store: true,
      terminalName: "haptlvfc",
      accountingDocumentsExport: true,
    },
  },
  hapoel_akko: {
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "tltest",
    },
  },
  maccabi_herzliya: {
    category: "soccer",
    features: {
      requireAccountingExportNumber: true,
    },
    payments: {
      enabled: true,
      store: true,
      terminalName: "machertzl",
      accountingDocumentsExport: true,
    },
  },
  tzafririm_holon_school: {
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "tltest",
    },
  },
  hapoel_haifa_bc: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "haphaifabc",
    },
  },
  maccabi_ta_center: {
    category: "superclub",
    payments: {
      enabled: true,
      accountingDocumentsExport: true,
    },
  },
  maccabi_ta_center_waterpolo: {
    category: "waterpolo",
    features: {
      requireAccountingExportNumber: true,
    },
    payments: {
      enabled: true,
      store: true,
      terminalName: "maccabiltd",
      accountingDocumentsExport: true,
    },
  },
  maayan_tene: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "mayantene",
    },
  },
  maccabi_ashdod_swimmers: {
    category: "swimming",
    payments: {
      enabled: true,
      store: true,
      terminalName: "ashdodswim",
    },
  },
  hapoel_mate_yehuda: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "hapmateybc",
    },
  },
  tabasko_olympic_studio: {
    category: "dancing",
    payments: {
      enabled: true,
      store: true,
      terminalName: "tltest",
    },
  },
  mor_holon: {
    category: "soccer",
    payments: {
      enabled: true,
      store: true,
      terminalName: "samorholon",
    },
  },
  pardes_hana_karkur: {
    category: "basketball",
    payments: {
      enabled: true,
      store: true,
      terminalName: "macpardes",
    },
  },
};

export default config;
